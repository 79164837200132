import classNames from 'classnames';
import React, { PropsWithChildren, forwardRef } from 'react';

import './style.css';

interface IStackProps {
	className?: string;
	direction: 'horizontal' | 'vertical';
	[key: string]: any;
}

const Stack = forwardRef<HTMLDivElement, PropsWithChildren<IStackProps>>(
	({ children, className, direction = 'vertical', ...props }, ref) => (
		<div className={classNames(direction === 'horizontal' ? 'hstack' : 'vstack', className)} {...props} ref={ref}>
			{children}
		</div>
	)
);

const HStack = forwardRef<HTMLDivElement, PropsWithChildren<IStackProps>>(({ children, ...props }, ref) => (
	<Stack direction="horizontal" {...props} ref={ref}>
		{children}
	</Stack>
));

export { Stack, HStack };
