import classNames from 'classnames';
import React, { FunctionComponent, PropsWithChildren } from 'react';

interface IContainerProps {
	className?: string;
}

const Container: FunctionComponent<PropsWithChildren<IContainerProps>> = ({ children, className }) => {
	return <div className={classNames(className, 'container')}>{children}</div>;
};

export default Container;
