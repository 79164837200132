import classNames from 'classnames';
import React, { memo } from 'react';

import { Spinner } from '../elements';
import InfiniteScroll from './InfiniteScroll.tsx';

const ItemListInfinite = ({
	ids,
	entities,
	sections,
	renderItem,
	loading = false,
	className = 'item-list',
	loaderComponent,
	skeletonComponent,
	emptyComponent,
	...props
}) => {
	if (!loaderComponent) loaderComponent = <Spinner className="py-10" />;

	if (skeletonComponent && !ids && !sections)
		return <div className={classNames('space-y-4 !overflow-hidden', className)}>{skeletonComponent}</div>;

	if ((ids === undefined && sections === undefined && !loading) || (!ids && !sections && loading))
		return loaderComponent;

	if (!ids?.length && !sections?.length && !loading) return emptyComponent || <div>Список пуст</div>;

	let dataLength = 0;

	if (sections) {
		for (let i in sections) {
			dataLength += 1;
			dataLength += sections[i].data.length;
		}
	} else {
		dataLength = ids.length;
	}

	const data = sections ? sections : ids ? ids.map((id) => entities.get(id)) : [];
	const keyExtractor = (item) => (item ? item.id.toString() : null);

	return (
		<InfiniteScroll
			className={classNames('space-y-4', className)}
			loader={loaderComponent}
			dataLength={dataLength}
			{...props}
		>
			{data.map((item, index) => {
				return (
					<React.Fragment key={sections ? index : keyExtractor(item)}>{renderItem(item, index)}</React.Fragment>
				);
			})}
		</InfiniteScroll>
	);
};

export default memo(
	ItemListInfinite,
	(a, b) => a.loading === b.loading && a.ids === b.ids && a.entities === b.entities && a.sections === b.sections
);
