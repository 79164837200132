import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { forwardRef, useRef } from 'react';
import { Button } from 'vgui';

import './style.css';

const InputSearch = forwardRef(
	(
		{
			value,
			className,
			typeInput = 'text',
			placeholder = 'Я ищу...',
			timeout = false,
			onClear,
			onSearch,
			onChange,
			...props
		},
		ref,
	) => {
		const timer = useRef();

		const stopTimer = () => {
			if (timer.current) {
				clearTimeout(timer.current);
				timer.current = null;
			}
		};

		const handleChange = (e) => {
			stopTimer();

			if (timeout)
				timer.current = setTimeout(function () {
					if (e.target.value?.length === 0) return handleClear();
					handleSearch();
				}, timeout);

			return onChange && onChange(e);
		};

		const handleClear = () => {
			return onClear && onClear();
		};

		const handleSearch = () => {
			stopTimer();
			return onSearch && onSearch();
		};

		return (
			<div className={'flex flex-row w-full relative overflow-hidden'}>
				<div className={'flex w-full relative overflow-hidden rounded-l border border-r-0 text-left '}>
					<div className={'absolute right-0 h-full flex z-10'}>
						<button
							type="button"
							className={
								'h-full px-2 hover:!bg-transparent focus:!bg-transparent text-gray-300 hover:text-muted'
							}
							onClick={handleClear}
						>
							<XMarkIcon className={'w-5 h-5'} />
						</button>
					</div>

					<input
						type={typeInput}
						className={classNames(`input-text m-0 rounded-l border-0 pr-12`, className)}
						placeholder={placeholder}
						value={value}
						autoComplete="off"
						autoCorrect="off"
						autoCapitalize="none"
						{...props}
						ref={ref}
						onChange={handleChange}
					/>
				</div>
				<Button
					variant="secondary"
					size="big"
					className="rounded-l-none border border-gray-300"
					onClick={handleSearch}
				>
					<MagnifyingGlassIcon className="w-4 h-4 text-muted" />
				</Button>
			</div>
		);
	},
);

export default InputSearch;
