import classNames from 'classnames';
import { randomInteger } from 'helpers/random';
import { Fragment, memo } from 'react';

import { HStack, Stack } from '../stack';

const Skeleton = ({ w, h, rounded = 'rounded', className, ...etc }) => (
	<div className={classNames(className, `w-${w} h-${h} bg-gray-200 dark:bg-gray-700 ${rounded}`)} {...etc}></div>
);

Skeleton.Icon = ({ size = 'md' }) => <Skeleton className={`avatar-${size}`} />;

Skeleton.Avatar = ({ size = 'sm' }) => <Skeleton className={`avatar-${size}`} rounded="rounded-full" />;

Skeleton.Title = ({ w = 24, ...etc }) => <Skeleton h={4} w={w} {...etc} />;

Skeleton.String = ({ w = 60, ...etc }) => <Skeleton h={3} w={w} {...etc} />;

Skeleton.Text = memo(
	({ rows = 7, h = 3, ...etc }) => (
		<Stack className="gap-2">
			{Array(rows)
				.fill(undefined)
				.map((_, i) => (
					<HStack className="gap-2" key={i} {...etc}>
						<Skeleton h={h} w="full" style={{ width: `${randomInteger(10, 100)}rem` }} />
						<Skeleton h={h} w="full" style={{ width: `${randomInteger(10, 50)}rem` }} />
						<Skeleton h={h} w="full" />
					</HStack>
				))}
		</Stack>
	),
	(a, b) => a.rows === b.rows,
);

Skeleton.Header = ({ size = 'sm' }) => (
	<HStack className="gap-4 items-start">
		<Skeleton.Icon {...{ size }} />
		<Stack className="gap-2">
			<Skeleton.Title />
			<Skeleton.String />
		</Stack>
	</HStack>
);

Skeleton.RoundButton = () => <Skeleton h={6} w={12} rounded="rounded-full" />;

Skeleton.Repeat = ({ count = 7, children }) =>
	Array(count)
		.fill(undefined)
		.map((_, i) => <Fragment key={i}>{children}</Fragment>);

export default Skeleton;
