import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export const Confirmed = ({ className }) => {
	return (
		<span className={classNames('inline-flex ml-1 !w-4 !h-4 text-primary', className)}>
			<CheckBadgeIcon data-bs-toggle="tooltip" data-bs-placement="top" title="Проверенная группа" />
		</span>
	);
};

export default Confirmed;
