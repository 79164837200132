import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { Stack } from '../stack';

const Stub = ({ children, icon = <ExclamationTriangleIcon /> }) => (
	<div className="flex min-h-[90vh] min-w-full">
		<Stack className="items-center text-center justify-center">
			<span className="w-32 text-muted mb-8">{icon}</span>
			{children}
		</Stack>
	</div>
);

export default Stub;
