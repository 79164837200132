import { FaceSmileIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Button } from '../button';
import { PopoverNew } from '../popover';
import emojiList from './emoji.json';
import * as CategoryIcon from './icons';
import './style.css';

const baseUrl = 'https://cdn.vashgorod.ru/twemoji/assets/svg/';

const Emojipicker = (props) => {
	const { onClick } = props;
	const [active, setActive] = useState(emojiList.categories[0].id);
	const [categoryList, setList] = useState(emojiList.categories[0].emojis);

	useEffect(() => {
		const category = emojiList.categories.find(({ id }) => active === id);

		setList(category.emojis);
	}, [active]);

	const handleClick = (skins, setIsComponentVisible) => {
		if (onClick) onClick(skins[0].native);
		setIsComponentVisible(false);
	};

	return (
		<PopoverNew
			className="right-0 p-0 h-52"
			rootElement={({ ref, onClick, isComponentVisible }) => (
				<Button ref={ref} variant="success-fill" size="small" className="h-9 !px-1 text-muted" onClick={onClick}>
					<FaceSmileIcon className={classNames('w-5', { 'text-primary': isComponentVisible })} />
				</Button>
			)}
		>
			{({ setIsComponentVisible }) => (
				<div className="emoji-picker">
					<div className="emoji-categories">
						{emojiList.categories.map(({ id }) => {
							const Icon = CategoryIcon[id.charAt(0).toUpperCase() + id.slice(1)];

							return (
								<Button
									variant="transparent"
									className={classNames('w-7 h-7 !p-1.5', { '!fill-primary': id === active })}
									key={id}
									onClick={() => setActive(id)}
								>
									<Icon className="w-full h-full" />
								</Button>
							);
						})}
					</div>
					<div className="emoji-canvas">
						{(Object.values(emojiList.emojis) as Array<{ id: number; skins: Array<any> }>).map(
							({ id, skins }) => {
								return (
									categoryList.includes(id) && (
										<div
											onClick={() => handleClick(skins, setIsComponentVisible)}
											key={id}
											className="emoji-button"
											style={{
												backgroundSize: '1.25rem',
												backgroundImage: `url(${baseUrl}${skins[0].unified
													.replace('-fe0f', '')
													.replace(/^00/, '')}.svg)`,
											}}
										/>
									)
								);
							},
						)}
					</div>
				</div>
			)}
		</PopoverNew>
	);
};

export default Emojipicker;
