const VGIcon = ({ className }) => {
	return (
		<svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 20">
			<path
				d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M15.2,11.5c0.3,0.4,0.3,0.9,0,1.3l-1.2,2
	c-0.2,0.4-0.6,0.6-1.1,0.6H7.8l-1.7,2.1l-0.2-2.1c-0.6-0.1-1-0.6-1-1.2V5.9c0,0,0,0,0,0c0-0.7,0.6-1.3,1.3-1.3h6.8
	c0.4,0,0.8,0.2,1.1,0.6l1.3,2.1c0.3,0.4,0.3,0.9,0,1.3l-0.9,1.4L15.2,11.5z"
				fill="#00BC00"
			/>
		</svg>
	);
};

export default VGIcon;
