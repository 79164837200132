import { isLocal, relative } from 'helpers/utils';
import { Link as RLink } from 'react-router-dom';

/* eslint-disable react/jsx-no-target-blank */

const ExternalLink = ({ href, children, ...props }) => (
	<a href={href} target="_blank" rel="noopener nofollow" {...props}>
		{children}
	</a>
);

const Link = ({ to, ...props }) => {
	return props?.relative ? (
		<RLink to={to} {...props} />
	) : isLocal(to) ? (
		<RLink to={relative(to)} {...props} />
	) : (
		<ExternalLink href={to} {...props} />
	);
};

export default Link;
