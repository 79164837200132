import { Link } from 'vgui';

const ListLink = ({ title, icon, url, onClick }) => (
	<Link to={url} onClick={onClick} className="listlink">
		<span className="listlink-icon">{icon}</span>
		<span>{title}</span>
	</Link>
);

export default ListLink;
