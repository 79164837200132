import make from 'vgui/helpers/make';

import './style.css';

const Wizard = (props) => make('wizard', props);

Wizard.Header = (props) => make('wizard-header', props);
Wizard.Body = (props) => make('wizard-body', props);
Wizard.Footer = (props) => make('wizard-footer', props);

export default Wizard;
