import React from 'react';

const LazyImage = (props) => {
	if ('loading' in HTMLImageElement.prototype) {
		return <img alt="img" {...props} loading="lazy" border={0} />;
	}

	return <img alt="img" {...props} />;
};

export default LazyImage;
