import make from 'vgui/helpers/make';
import './style.css';

const Paper = (props) => make('paper', props);

Paper.Header = (props) => make('paper-header', props);
Paper.Body = (props) => make('paper-body', props);
Paper.Footer = (props) => make('paper-footer', props);

export default Paper;
