const sizes = {
	sm: [32, 3, 0.6],
	base: [48, 4, 0.9],
	lg: [80, 5, 1.5],
};

const CircularProgressBar = ({ value = 25, size = 'base', bg = '#ddd', color = 'red' }) => {
	if (!value || value === undefined || value < 0) value = 0;
	if (value > 100) value = 100;
	const [sqSize, strokeWidth, fontSize] = sizes[size] || sizes['base'];
	const radius = (sqSize - strokeWidth) / 2;
	const dashArray = radius * Math.PI * 2;
	const dashOffset = dashArray - (dashArray * value) / 100;

	return (
		<svg width={sqSize} height={sqSize} viewBox={`0 0 ${sqSize} ${sqSize}`}>
			<circle cx={sqSize / 2} cy={sqSize / 2} r={radius} fill="none" stroke={bg} strokeWidth={`${strokeWidth}px`} />
			<circle
				cx={sqSize / 2}
				cy={sqSize / 2}
				r={radius}
				fill="none"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={`${strokeWidth}px`}
				// Start progress marker at 12 O'Clock
				transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
				style={{
					strokeDasharray: dashArray,
					strokeDashoffset: dashOffset,
				}}
			/>
			<text
				className="circle-text"
				x="50%"
				y="50%"
				dy=".3em"
				textAnchor="middle"
				fontSize={`${fontSize}em`}
				fontWeight="bold"
				fill={color}
			>
				{`${value}%`}
			</text>
		</svg>
	);
};

export default CircularProgressBar;
