import classNames from 'classnames';

import './style.css';

const ListGroup = (props) => {
	const { children, className, ...stuff } = props;
	return (
		<div className={'wrap-list-group'}>
			<ul className={classNames('list-group', className)} {...stuff}>
				{children}
			</ul>
		</div>
	);
};

const Item = ({ children, className, onClick = () => {} }) => {
	return (
		<li className={classNames('list-group-item', className)} onClick={onClick}>
			{children}
		</li>
	);
};

ListGroup.Item = Item;

export default ListGroup;
