import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import useOutsideClick from 'hooks/useOutsideClick';
import { useEffect } from 'react';
import { Button, DropdownNew } from 'vgui';

import './style.css';

const DropdownButton = ({
	children,
	className,
	options,
	iconUp = <ChevronUpIcon className="w-3 h-3" />,
	iconDown = <ChevronDownIcon className="w-3 h-3" />,
	size,
	variant,
	disabled,
	autoclose = 10000,
}) => {
	const { ref, isComponentVisible, setIsComponentVisible } = useOutsideClick(false);

	useEffect(() => {
		if (disabled) setIsComponentVisible(false);
	}, [disabled, setIsComponentVisible]);

	let timer = null;
	const toggleDropDown = () => {
		clearTimeout(timer);
		if (!isComponentVisible) {
			timer = setTimeout(() => {
				setIsComponentVisible(false);
				clearTimeout(timer);
			}, autoclose);
		}

		setIsComponentVisible((prevState) => !prevState);
	};

	if (!options.length || options.every((v) => !v)) return;

	return (
		<>
			<Button ref={ref} className={className} onClick={toggleDropDown} {...{ size, variant, disabled }}>
				{children}
				{isComponentVisible && iconUp}
				{!isComponentVisible && iconDown}
			</Button>
			<DropdownNew {...{ isComponentVisible, setIsComponentVisible, ref }} className="right-0" options={options} />
		</>
	);
};

export default DropdownButton;
