import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { FunctionComponent, MouseEvent, PropsWithChildren, ReactElement, useState } from 'react';

import { Button } from '../button';
import './style.css';

interface IAccordionProps {
	defaultIndex?: number;
	className: string;
}

interface IAccordionItemProps {
	title: string;
	children: any;
	active: boolean;
	onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

type IAccordion = FunctionComponent<PropsWithChildren<IAccordionProps>> & {
	Item: FunctionComponent<PropsWithChildren<IAccordionItemProps>>;
};

const Accordion: IAccordion = ({ children, defaultIndex = 0, className }) => {
	const [openIndex, setOpenIndex] = useState(defaultIndex);
	const childArray = React.Children.toArray(children) as ReactElement<IAccordionItemProps>[];

	return (
		<div className={classNames('accordions', className)}>
			{childArray.map(
				(item, index) =>
					item && (
						<AccordionItem
							{...item.props}
							key={index}
							active={index === openIndex}
							onClick={() => setOpenIndex(index === openIndex ? null : index)}
						/>
					),
			)}
		</div>
	);
};

const AccordionItem: FunctionComponent<PropsWithChildren<IAccordionItemProps>> = ({
	title,
	children,
	active = false,
	onClick,
}) => (
	<div className={classNames('accordions-item', { active })}>
		<Button onClick={onClick} variant="success-fill" size="small" className="w-full justify-between items-center">
			<h2 className="m-0 text-base">{title}</h2>
			<ChevronDownIcon className={classNames('w-4 h-4 transition-transform', { 'rotate-180': active })} />
		</Button>
		<div className="content">{children}</div>
	</div>
);

Accordion.Item = AccordionItem;

export default Accordion;
