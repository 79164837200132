import classNames from 'classnames';
import { emojify } from 'helpers/emoji';
import { toUnicode } from 'helpers/punycode.ts';
import { useState, forwardRef, useRef, useEffect, useImperativeHandle } from 'react';
import ContentEditable from 'react-contenteditable';
import { Emojipicker } from 'vgui';

import './style.css';

const Editor = forwardRef(
	(
		{
			className,
			editorClassName,
			placeholder,
			value = '',
			useEmoji = false,
			border = false,
			disabled = false,
			onBlur,
			onFocus,
			onEnterPress,
			onKeyUp,
			onChange,
			onPaste,
			variant = 'main',
			size = 'default',
		},
		ref,
	) => {
		const [inFocus, setInFocus] = useState(false);
		const [openedPicker, setOpenedPicker] = useState(false);

		const inputRef = useRef();
		const pickerRef = useRef();

		function insertText(text) {
			if (window.getSelection) {
				const selection = window.getSelection();

				if (selection.getRangeAt && selection.rangeCount) {
					const range = selection.getRangeAt(0);

					const arr = text.split(/\r?\n|\r/).reverse();

					range.deleteContents();

					if (arr.length === 1) {
						insertRow(range, arr[0]);
					} else {
						for (let key in arr) {
							insertRow(range, arr[key]);
							if (Number(key) + 1 < arr.length) {
								range.insertNode(document.createElement('br'));
							}
						}
					}

					range.collapse(false);
				}
			} else if (document.selection && document.selection.createRange) {
				document.selection.createRange().text = text;
			}
		}

		const insertRow = (range, inputRow) => {
			const row = inputRow.split(/([\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}])/gu).reverse();

			for (let key in row) {
				if (/([\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}])/gu.test(row[key])) {
					range.insertNode(document.createRange().createContextualFragment(emojify(row[key])));
				} else {
					range.insertNode(document.createTextNode(row[key]));
				}
			}
		};

		const setValue = (value) => {
			if (onChange) onChange(value);
		};

		const handleEmojiChoose = (emoji) => {
			setValue(value + emojify(emoji));
			inputRef.current.focus();
		};

		const handleFocus = () => {
			setInFocus(true);
			if (onFocus) onFocus();
		};

		const handleKeyPress = (e) => {
			if (e.key === 'Enter' && !e.shiftKey && onEnterPress) {
				e.preventDefault();
				onEnterPress();
			}
		};

		const handleBlur = () => {
			setInFocus(false);
			if (onBlur) onBlur();
		};

		const handlePaste = (e) => {
			e.preventDefault();

			const data = (e.originalEvent || e).clipboardData;

			if (onPaste) onPaste(data);

			const text = data.getData('text/plain');

			if (text.length) {
				insertText(toUnicode(text));
				setValue(inputRef.current.innerHTML);
			}
		};

		useEffect(() => {
			const handleOutClick = (e) => {
				if (openedPicker && !pickerRef.current.contains(e.target)) {
					setOpenedPicker(false);
					e.stopPropagation();
				}
			};

			if (useEmoji) {
				document.addEventListener('click', handleOutClick, true);

				return () => {
					document.removeEventListener('click', handleOutClick, true);
				};
			}
		}, [useEmoji, openedPicker]);

		useImperativeHandle(ref, () => ({
			setValue,
			focus: () => {
				inputRef.current.focus();
			},
			blur: () => {
				inputRef.current.blur();
			},
		}));

		return (
			<div
				onKeyPress={handleKeyPress}
				className={classNames(
					className,
					border ? ['editor', disabled ? 'disabled' : variant, size] : '',
					inFocus && 'focused',
				)}
			>
				<ContentEditable
					onKeyDown={(e) => {
						if (e.key === 'Enter' && e.ctrlKey) {
							e.preventDefault();

							document.execCommand('insertLineBreak');
						}
					}}
					innerRef={inputRef}
					placeholder={placeholder}
					html={value}
					disabled={disabled}
					className={classNames(editorClassName, 'editor-input overflow-x-hidden overflow-y-auto')}
					onPaste={handlePaste}
					onBlur={handleBlur}
					onFocus={handleFocus}
					onKeyUp={onKeyUp}
					onChange={(e) => setValue(e.target.value)}
				/>
				{useEmoji && <Emojipicker onClick={handleEmojiChoose} />}
			</div>
		);
	},
);

export default Editor;
