import classNames from 'classnames';
import React from 'react';

import './style.css';

const Select = React.forwardRef((props, ref) => {
	const { children, className, error, ...stuff } = props;
	return (
		<select className={classNames('selector', className, error ? 'selector-error' : '')} {...stuff} ref={ref}>
			{children}
		</select>
	);
});

const Item = ({ children, value, className, onClick = () => {} }) => {
	return (
		<option className={classNames('option', className)} value={value} onClick={onClick}>
			{children}
		</option>
	);
};

Select.Item = Item;

export default Select;
