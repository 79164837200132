import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { format, isValid, parse } from 'date-fns';
import { ru } from 'date-fns/locale';
import { dateFormat } from 'helpers/date';
import React, { FocusEvent, forwardRef, ForwardRefRenderFunction, PropsWithChildren, useState, useEffect } from 'react';
import { DayPicker, useDayPicker } from 'react-day-picker';

import { Button } from '../button';
import { InputNew } from '../input';
import { PopoverNew } from '../popover';
import { HStack, Stack } from '../stack';

interface IDatepicker {
	placeholder?: string;
	withTime?: boolean;
	size?: 'default' | 'small';
	defaultValue?: Date;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
	onChange?: (value: string | Date) => void;
	name?: string;
}

const DayButton = (props) => {
	const { day, modifiers } = props;

	return (
		<Button
			{...props}
			disabled={modifiers.outside}
			size="rect"
			className={classNames('leading-4', {
				'!bg-transparent': modifiers.outside,
				'!text-primary': modifiers.selected,
			})}
			variant="transparent"
		>
			{format(day.date, 'dd')}
		</Button>
	);
};

const MonthCaption = ({ calendarMonth }) => {
	const { goToMonth, nextMonth, previousMonth } = useDayPicker();
	return (
		<HStack className="w-full mb-1">
			<Button
				disabled={!previousMonth}
				round
				variant="transparent"
				size="rect"
				onClick={() => previousMonth && goToMonth(previousMonth)}
			>
				<ChevronLeftIcon className="w-4 h-4" />
			</Button>
			<span className="w-full text-center text-lg select-none">{dateFormat(calendarMonth.date, 'F Y')}</span>
			<Button
				disabled={!nextMonth}
				round
				variant="transparent"
				size="rect"
				onClick={() => nextMonth && goToMonth(nextMonth)}
			>
				<ChevronRightIcon className="w-4 h-4" />
			</Button>
		</HStack>
	);
};

const Datepicker: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren<IDatepicker>> = (props, ref) => {
	const { placeholder, withTime = true, size = 'default', defaultValue, onBlur, onChange, name, ...other } = props;
	const dateFormat = withTime ? 'dd.MM.y kk:mm' : 'dd.MM.y';
	const [selected, setSelected] = useState<Date>(defaultValue || new Date());
	const [time, setTime] = useState(format(selected, 'kk:mm'));
	const [value, setInputValue] = useState(defaultValue ? format(selected, dateFormat) : '');
	const [month, setMonth] = useState<Date>(selected);

	useEffect(() => {
		setMonth(selected);
		if (onChange) onChange(value ? selected : '');
	}, [selected, onChange, value]);

	const handleTimeChange = (e) => {
		setTime(e.target.value);
		const newTime = parse(e.target.value, 'kk:mm', new Date());
		if (isValid(newTime)) {
			const dateWithTime = parse(`${format(selected, 'dd.MM.y')} ${e.target.value}`, 'dd.MM.y kk:mm', new Date());
			setSelected(dateWithTime);
			setInputValue(format(dateWithTime, dateFormat));
		}
	};

	const handleTimeBlur = (e) => {
		const time = parse(e.currentTarget.value, 'kk:mm', new Date());

		if (!isValid(time)) {
			setTime(format(selected, 'kk:mm'));
		}
	};

	const handleChange = (e) => {
		setInputValue(e.currentTarget.value);

		const date = parse(e.currentTarget.value, dateFormat, new Date());

		if (isValid(date)) {
			setSelected(date);
			setTime(format(date, 'kk:mm'));
		}
	};

	const handleBlur = (e) => {
		const date = parse(e.currentTarget.value, dateFormat, new Date());

		if (!isValid(date) && value) {
			setTime(format(selected, 'kk:mm'));
			setInputValue(format(selected, dateFormat));
		}

		if (onBlur) onBlur(e);
	};

	const onDayClick = (date: Date, setIsComponentVisible: (val: boolean) => void) => {
		const dateWithTime = parse(`${format(date, 'dd.MM.y')} ${time}`, 'dd.MM.y kk:mm', new Date());

		setSelected(withTime ? dateWithTime : date);

		if (dateWithTime) {
			setInputValue(format(dateWithTime, dateFormat));
			setIsComponentVisible(false);
		} else {
			setInputValue('');
		}
	};

	return (
		<PopoverNew
			className="right-0 min-w-fit"
			rootElement={({ ref: inputRef, onClick, isComponentVisible, setIsComponentVisible }) => (
				<InputNew
					onFocus={() => setIsComponentVisible(true)}
					onChange={handleChange}
					onBlur={handleBlur}
					size={size}
					ref={inputRef}
					name={name}
					placeholder={placeholder}
					title={placeholder}
					{...{ value }}
				>
					<Button
						variant="success-fill"
						size="small"
						onMouseDown={(e) => {
							e.preventDefault();
							onClick();
							inputRef.current[isComponentVisible ? 'blur' : 'focus']();
						}}
					>
						<CalendarIcon className={classNames('w-4 h-4')} />
					</Button>
				</InputNew>
			)}
		>
			{({ setIsComponentVisible }) => {
				return (
					<Stack className="gap-2">
						<DayPicker
							{...other}
							month={month}
							onDayClick={(date) => onDayClick(date, setIsComponentVisible)}
							mode="single"
							selected={selected}
							showOutsideDays
							hideNavigation
							components={{
								MonthCaption,
								DayButton,
							}}
							locale={ru}
						/>
						{withTime && (
							<InputNew
								placeholder="Время"
								className="text-center"
								value={time}
								onChange={handleTimeChange}
								onBlur={handleTimeBlur}
							/>
						)}
					</Stack>
				);
			}}
		</PopoverNew>
	);
};

export default forwardRef(Datepicker);
