import { FaceFrownIcon } from '@heroicons/react/24/outline';

import { Stack } from '../stack';

export const EmptyComponent = ({ message, icon, children }) => {
	const Component = icon || FaceFrownIcon;

	return (
		<Stack className="justify-center items-center min-h-[80vh] text-gray-500">
			<Component className="w-24 h-24 mb-4" />
			{children || <p className="text-lg">{message}</p>}
		</Stack>
	);
};

export default EmptyComponent;
