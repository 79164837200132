import classNames from 'classnames';
import React, { MouseEventHandler, PropsWithChildren } from 'react';

import './style.css';

export interface IInputProps {
	type?: 'text' | 'password' | 'date';
	size?: 'small' | 'default' | 'big';
	variant?: 'main' | 'danger';
	round?: boolean;
	disabled?: boolean;
	className?: string;
	classInput?: string;
	onClick?: MouseEventHandler<HTMLDivElement>;
	onMouseDown?: MouseEventHandler<HTMLDivElement>;
	[key: string]: any;
}

const Input = React.forwardRef<HTMLInputElement, PropsWithChildren<IInputProps>>((props, ref) => {
	const {
		className,
		classInput,
		type = 'text',
		disabled,
		variant = 'main',
		size = 'default',
		round = false,
		children,
		onClick,
		onMouseDown,
		...other
	} = props;

	return (
		<div
			onClick={onClick}
			onMouseDown={onMouseDown}
			className={classNames('input', className, { round }, disabled ? 'disabled' : variant, size)}
		>
			<input type={type} {...other} ref={ref} disabled={disabled} className={classInput} />
			{children}
		</div>
	);
});

export default Input;
