import FsLightbox from 'fslightbox-react';
import useScrollBlock from 'hooks/useScrollBlock';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

const LightBox = (props) => {
	const [blockScroll, allowScroll] = useScrollBlock();

	const handleClose = useCallback(() => {
		if (document.fullscreenElement) document.exitFullscreen();
		allowScroll();
	}, [allowScroll]);

	useEffect(() => {
		return () => handleClose();
	}, [allowScroll, handleClose]);

	return createPortal(
		<FsLightbox {...props} onOpen={blockScroll} onClose={handleClose} exitFullscreenOnClose loadOnlyCurrentSource />,
		document.querySelector('#root')
	);
};

export default LightBox;
