import classNames from 'classnames';
import React, { useState } from 'react';
import useAutoOptionsPosition from 'vgui/hooks/useAutoOptionsPosition.tsx';

import { Button } from '../button';
import { Spinner } from '../elements';
import './style.css';

interface IDropdown {}

type DropdownComponent = IDropdown &
	React.RefAttributes<HTMLDivElement> & {
		Option?: (props: any) => JSX.Element;
	};

const Dropdown: DropdownComponent = React.forwardRef<HTMLDivElement, IDropdown>((props, ref) => {
	const {
		maxHeight,
		render,
		loading,
		className,
		optionPlaceholder,
		isComponentVisible,
		setIsComponentVisible,
		options = [],
		onChange,
		id,
	} = props;
	const { optionsRef } = useAutoOptionsPosition(isComponentVisible, ref, options);
	const [selectedId, setId] = useState(id || '');

	return (
		<div
			style={{ maxHeight }}
			ref={optionsRef}
			className={classNames('options', { hidden: !isComponentVisible }, className)}
		>
			{loading ? (
				<div className="px-3 py-2">
					<Spinner size="xs" />
				</div>
			) : options.length ? (
				options.map((item, key) => {
					if (!item) return '';

					const { value, disabled = false, onClick: defClick } = item;
					const id = 'id' in item ? item.id : key;
					const onClick = () => {
						if (onChange) setId(id);
						if (defClick) defClick();
						if (onChange) onChange({ id, value });
						setIsComponentVisible(false);
					};
					const selected = selectedId === id || item.selected === true;

					return render ? (
						render({ selected, item, onClick, key: id, disabled })
					) : value === '-' ? (
						<hr key={id} />
					) : (
						<Option selected={selected} onClick={onClick} key={id} disabled={disabled}>
							{value}
						</Option>
					);
				})
			) : (
				<span className="text-sm px-3 py-1.5 block">{optionPlaceholder || 'Ничего не найдено'}</span>
			)}
		</div>
	);
});

const Option = ({ children, onClick, selected, disabled }) => {
	return (
		<Button
			onMouseDown={(e) => e.preventDefault()}
			onClick={onClick}
			size="small"
			className={classNames('option', selected && 'selected')}
			variant="transparent"
			disabled={disabled}
		>
			{children}
		</Button>
	);
};

Dropdown.Option = Option;

export default Dropdown;
