import classNames from 'classnames';
import React, { useRef } from 'react';
import { HStack } from 'vgui';

const Checkbox = React.forwardRef(({ className, error, label, defaultCheck = false, onClick, ...props }, ref) => {
	const boxRef = useRef();

	return (
		<HStack
			className={classNames(className, 'cursor-pointer')}
			ref={boxRef}
			onClick={(e) => {
				e.preventDefault();
				boxRef.current.childNodes[0].click();
				if (onClick) onClick(e);
			}}
		>
			<input
				className={classNames('checkbox pointer-events-none', error ? 'checkbox-error' : '')}
				type="checkbox"
				{...props}
				ref={ref}
			/>
			{label && <span className={classNames(error ? 'checkbox-label-error' : 'checkbox-label')}>{label}</span>}
		</HStack>
	);
});

export default Checkbox;
