import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Container, HStack } from 'vgui';

const BottomBar = ({ children, className }) => {
	return (
		<div className={classNames(className, 'bottombar')}>
			<Container className="h-full shadow-[0_-4px_6px_rgba(0,0,0,0.1)]">
				<HStack className="justify-around h-full items-stretch">{children}</HStack>
			</Container>
		</div>
	);
};

BottomBar.Button = ({ className, active, children, ...props }) => {
	return (
		<Link {...props} className={classNames('flex grow justify-center items-center', { active }, className)}>
			<span className="w-6 relative">{children}</span>
		</Link>
	);
};

export default BottomBar;
