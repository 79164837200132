import { datePretty } from 'helpers/date';
import { useEffect, useState } from 'react';

const DatePretty = ({ value, className }) => {
	const [pretty, setPretty] = useState(datePretty(value));

	useEffect(() => {
		if (!value) return;

		const updater = setInterval(() => {
			setPretty(datePretty(value));
		}, 10000);

		return () => {
			clearInterval(updater);
		};
	}, [value]);

	return (
		!!value && (
			<time className={className} dateTime={value}>
				{pretty}
			</time>
		)
	);
};

export default DatePretty;
