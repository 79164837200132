import classNames from 'classnames';
import React from 'react';

import './style.css';

const Input = React.forwardRef(({ className, type = 'text', placeholder, error, ...props }, ref) => {
	return (
		<input
			type={type}
			className={classNames(`input-text`, className, error ? 'input-text-error' : '')}
			placeholder={placeholder}
			{...props}
			ref={ref}
		/>
	);
});

export default Input;
