const PhoneIcon = ({ ...props }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="currentColor" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 3C12 1.89543 12.8954 1 14 1H33C34.1046 1 35 1.89543 35 3V45C35 46.1046 34.1046 47 33 47H14C12.8954 47 12 46.1046 12 45V3ZM16 8H31V32H16V8ZM23.5 44C25.433 44 27 42.433 27 40.5C27 38.567 25.433 37 23.5 37C21.567 37 20 38.567 20 40.5C20 42.433 21.567 44 23.5 44Z"
			/>
		</svg>
	);
};

export default PhoneIcon;
