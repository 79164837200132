import classNames from 'classnames';

const Navbar = ({ children, className }) => {
	return <div className={classNames(className, 'navbar')}>{children}</div>;
};

const NavbarBrand = ({ className, as, ...props }) => {
	const Component = as || (props.href ? 'a' : 'span');

	return <Component {...props} className={classNames(className)} />;
};

Navbar.Brand = NavbarBrand;

export default Navbar;
