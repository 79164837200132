import classNames from 'classnames';

const sizes = {
	xs: 'w-4 h-4 border-2',
	sm: 'w-8 h-8 border-[4px]',
	md: 'w-12 h-12 border-[5px]',
	lg: 'w-16 h-16 border-6',
};

const Spinner = ({ className = '', size = 'sm', fullscreen = false }) => (
	<div className={classNames('flex justify-center items-center', fullscreen && 'fullscreen min-h-[10rem]', className)}>
		<span className={classNames('spinner-border', sizes[size])} role="status"></span>
	</div>
);

export default Spinner;
