import React from 'react';

import './style.css';

const ItemList = React.forwardRef(({ ids, entities, renderItem, className = 'item-list' }, ref) => {
	if (!ids) return;

	const data = ids ? ids.map((id) => entities.get(id)) : [];
	const keyExtractor = (item) => (item ? item.id.toString() : null);

	return (
		<div className={className} ref={ref}>
			{data.map((item) => (
				<React.Fragment key={keyExtractor(item)}>{renderItem(item)}</React.Fragment>
			))}
		</div>
	);
});

export default ItemList;
