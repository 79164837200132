import { ArrowLeftIcon, ChevronDoubleUpIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Navbar, HStack, Pressable, Button } from 'vgui';

const ScrollButton = ({ hidden = true, onClick }) => (
	<div className="relative">
		<Button
			size="rect"
			variant="secondary"
			onClick={onClick}
			round
			className={classNames('scroll-button', hidden && 'hidden')}
		>
			<ChevronDoubleUpIcon className="w-6" />
		</Button>
	</div>
);

const handleScrollUp = () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	});
};

const AppBar = ({ title, left, right, onScrollUp = handleScrollUp }) => {
	const [scrollDirection, setScrollDirection] = useState('top');

	useEffect(() => {
		let lastScrollY = window.scrollY;

		const scrollHandler = () => {
			const scrollY = window.scrollY;

			if (scrollY < 5) return setScrollDirection('top');

			const direction = scrollY > lastScrollY ? 'down' : 'up';
			if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
				setScrollDirection(direction);
			}
			lastScrollY = scrollY > 0 ? scrollY : 0;
		};

		scrollHandler();
		window.addEventListener('scroll', scrollHandler);

		return () => {
			window.removeEventListener('scroll', scrollHandler);
		};
	}, [scrollDirection]);

	if (left === undefined) {
		left = (
			<AppBarButton title={'Назад'}>
				<ArrowLeftIcon />
			</AppBarButton>
		);
	}

	if (typeof title === 'string') {
		title = <span className="text-lg truncate">{title}</span>;
	}

	return (
		<Navbar
			className={classNames(
				'appbar',
				scrollDirection !== 'top' && 'shadow-md',
				scrollDirection === 'down' && '-top-14',
			)}
		>
			<HStack className="hstack gap-3 w-full">
				{left ? <div>{left}</div> : null}
				<div className="flex-1 items-center grow grid">{title}</div>
				{right ? <div>{right}</div> : null}
			</HStack>
			<ScrollButton hidden={scrollDirection === 'top'} onClick={onScrollUp} />
		</Navbar>
	);
};

const AppBarButton = ({ to = null, onClick, children, className, title }) => {
	const navigate = useNavigate();
	const { key } = useLocation();

	if (onClick === undefined) {
		onClick = () => {
			if (to) return navigate(to);
			if (key === 'default') return navigate('/');
			return navigate(-1);
		};
	}

	return (
		<Pressable onClick={onClick} className={classNames(className, 'w-6 hover:text-primary')} title={title}>
			{children}
		</Pressable>
	);
};

const AppBarTitle = ({ className, children }) => <span className={classNames(className, 'text-lg')}>{children}</span>;

const AppBarPanel = ({ className, children }) => (
	<HStack className={classNames(className, 'gap-4 justify-end')}>{children}</HStack>
);

AppBar.Title = AppBarTitle;
AppBar.Button = AppBarButton;
AppBar.Panel = AppBarPanel;

export default AppBar;
