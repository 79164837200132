import classNames from 'classnames';
import React, { useState } from 'react';

import { HStack } from '../stack';

interface ISwitch {
	checked: boolean;
	onChange: Function;
	label?: string;
	className: string;
}

const Switch = ({ checked = false, onChange, label, className }: ISwitch) => {
	const [isChecked, setChecked] = useState(checked);

	const handleToggle = () => {
		onChange?.(!isChecked);
		setChecked((prev) => !prev);
	};

	return (
		<HStack onClick={handleToggle} className={classNames('cursor-pointer gap-2', className)}>
			<div
				className={classNames(
					'relative rounded-full w-10 h-6 bg-gray-300',
					isChecked ? 'bg-green-500' : 'bg-gray-200'
				)}
			>
				<div
					className={classNames(
						'absolute left-1 top-1 w-4 h-4 rounded-full bg-white transform transition-transform',
						isChecked ? 'translate-x-4' : 'translate-x-0'
					)}
				/>
			</div>
			{label && <span>{label}</span>}
		</HStack>
	);
};

export default Switch;
