import classNames from 'classnames';
import useScrollBlock from 'hooks/useScrollBlock';
import { useEffect, useState, useRef } from 'react';

import './style.css';

const Offcanvas = (props) => {
	const { show, children, className, onHide = () => {}, ...stuff } = props;
	const [blockScroll, allowScroll] = useScrollBlock();
	const [startCoords, setStartCoords] = useState(0);
	const [currentCoords, setCurrentCoords] = useState(0);
	const ariaProps = show ? { 'aria-modal': 'true', role: 'dialog' } : { 'aria-hidden': 'true' };

	const ref = useRef(null);

	useEffect(() => {
		if (show) blockScroll();
		return () => allowScroll();
	}, [show, blockScroll, allowScroll]);

	if (!show) allowScroll();
	else blockScroll();

	const handleDown = (e) => {
		const y = e.touches[0]?.clientY;
		setStartCoords(y);
		setCurrentCoords(y);
	};

	const handleMove = (e) => {
		if (startCoords) {
			setCurrentCoords(e.touches[0]?.clientY);
		}
	};

	const handleUp = () => {
		if (currentCoords - startCoords > ref.current?.clientHeight / 3) onHide();

		setStartCoords(0);
		setCurrentCoords(0);
	};

	return (
		<div
			className={classNames('offcanvas-wrap', show ? 'show block' : ' hidden')}
			tabIndex="-1"
			{...ariaProps}
			onClick={onHide}
			onTouchStart={handleDown}
			onTouchMove={handleMove}
			onTouchEnd={handleUp}
		>
			<div className="offcanvas-dialog">
				<div
					className={classNames('offcanvas-content max-h-[99%]', className)}
					{...stuff}
					onClick={(e) => e.stopPropagation()}
					style={{ bottom: startCoords - currentCoords < 0 ? startCoords - currentCoords : 0 }}
				>
					<div className="w-full p-4 flex justify-center cursor-pointer" onTouchStart={handleDown}>
						<div
							className={classNames(
								'w-40 h-1 rounded-sm',
								currentCoords - startCoords > 10 ? 'bg-green-800' : 'bg-gray-500'
							)}
						></div>
					</div>
					<div ref={ref} className="w-full" onTouchStart={(e) => e.stopPropagation()}>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Offcanvas;
