import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Nav = ({ children, className, vertical = false }) => {
	return <nav className={classNames(className, 'nav', vertical ? 'flex flex-col' : '')}>{children}</nav>;
};

const NavLink = ({ className, as, active, children, ...props }) => {
	return (
		<Link
			className={classNames(
				className,
				'nav-link flex-inline items-center text-sm py-4 px-6 h-12 overflow-hidden text-dark dark:text-light text-ellipsis whitespace-nowrap rounded hover:text-primary hover:bg-gray-200 transition duration-300 ease-in-out"',
			)}
			data-mdb-ripple="true"
			data-mdb-ripple-color="dark"
			{...props}
		>
			{children}
		</Link>
	);
};

Nav.Link = NavLink;

export default Nav;
