import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import { Button, ListGroup } from 'vgui';

import './style.css';

const Dropdown = ({ children, className, items }) => {
	const { ref, isComponentVisible, setIsComponentVisible } = useOutsideClick(false);

	let timer = null;
	const toggleDropDown = () => {
		clearTimeout(timer);
		if (!isComponentVisible) {
			timer = setTimeout(() => {
				setIsComponentVisible(false);
				clearTimeout(timer);
			}, 30000);
		}

		setIsComponentVisible((prevState) => !prevState);
	};

	return (
		<div className="flex justify-center">
			<div className="dropdown relative" ref={ref}>
				<Button
					round
					size="rect"
					variant="transparent"
					className={classNames(
						{ 'text-gray-600 hover:text-gray-900 bg-gray-200': isComponentVisible },
						className
					)}
					onClick={toggleDropDown}
				>
					{children}
					{isComponentVisible && <ChevronUpIcon className="w-5 h-5" />}
					{!isComponentVisible && <ChevronDownIcon className="w-5 h-5" />}
				</Button>
				{isComponentVisible && (
					<div className="absolute right-0 z-20 top-[2.5rem] rounded-lg shadow-lg">
						<ListGroup className="text-left">
							{items.map((entitie, index) => {
								return (
									entitie && (
										<ListGroup.Item
											key={index}
											className={`text-sm py-1 whitespace-nowrap ${
												entitie.separator ? 'border-b' : '!border-0'
											}`}
											onClick={() => {
												entitie.onClick();
												setIsComponentVisible(false);
											}}
										>
											{entitie.item}
										</ListGroup.Item>
									)
								);
							})}
						</ListGroup>
					</div>
				)}
			</div>
		</div>
	);
};

export default Dropdown;
