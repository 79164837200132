import { ChevronDoubleUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import useScrollBlock from 'hooks/useScrollBlock';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';

import { Button } from '../button';
import { HStack } from '../stack';
import './style.css';

const Modal = (props) => {
	const { show = true, children, className, onHide, fullscreen = false, scrollable = false, ...stuff } = props;
	const [blockScroll, allowScroll] = useScrollBlock();
	const [opacity, setOpacity] = useState(0);
	const ariaProps = show ? { 'aria-modal': 'true', role: 'dialog' } : { 'aria-hidden': 'true' };

	useEffect(() => {
		if (show) blockScroll();
		setOpacity(100);
		return () => {
			allowScroll();
		};
	}, [allowScroll, blockScroll, show]);

	useEffect(() => {
		const handler = (e) => {
			if (e.keyCode === 27) {
				onHide();
			}
		};
		if (!fullscreen) window.addEventListener('keydown', handler);
		return () => window.removeEventListener('keydown', handler);
	}, [onHide, fullscreen]);

	const handleClick = () => {
		onHide();
		allowScroll();
	};

	if (!show) {
		allowScroll();
	} else {
		blockScroll();
	}

	return (
		<div
			className={classNames(
				`transition-opacity duration-150 opacity-${opacity} modal-wrap${show ? ' show block' : ' hidden'}`,
			)}
			tabIndex={'-1'}
			{...ariaProps}
		>
			<div
				className={
					className ||
					classNames(fullscreen ? 'modal-fullscreen' : 'modal-dialog', scrollable && 'modal-scrollable')
				}
			>
				<div className="modal-content" {...stuff} onClick={(e) => e.stopPropagation()}>
					{typeof children === 'object' ? children : children(handleClick)}
				</div>
			</div>
		</div>
	);
};

const Body = ({ children, className, id, onHide, hasUpButton = true }) => {
	const [pos, setPos] = useState(0);
	const ref = useRef(null);

	const scrollUp = () => {
		ref.current.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<div
			ref={ref}
			className={classNames('modal-body', className)}
			id={id}
			onScroll={(e) => setPos(e.target.scrollTop)}
		>
			{hasUpButton && (
				<div className={classNames('absolute right-24 top-2 z-10', pos < 50 && 'hidden')}>
					<HStack className="fixed z-10 gap-2">
						<Button size="rect" variant="transparent" onClick={scrollUp} round className="!bg-white shadow-md">
							<ChevronDoubleUpIcon className="w-5" />
						</Button>
						<Button size="rect" variant="transparent" onClick={onHide} round className="!bg-white shadow-md">
							<XMarkIcon className="w-5" />
						</Button>
					</HStack>
				</div>
			)}
			{children}
		</div>
	);
};

Modal.Body = Body;

Modal.Header = ({ children, className, onHide }) => {
	const hasHeaderActions = children instanceof Array && children[children.length - 1] instanceof Object;

	return (
		<div className={classNames('modal-header', className)}>
			{children}
			{!hasHeaderActions && (
				<Button round size="rect" variant="transparent" title="Закрыть" onClick={onHide}>
					<XMarkIcon className="w-5" />
				</Button>
			)}
		</div>
	);
};

Modal.HeaderActions = ({ children }) => <HStack className="gap-1">{children}</HStack>;

Modal.Footer = ({ children, className }) => <div className={classNames('modal-footer', className)}>{children}</div>;

export default Modal;
