import classNames from 'classnames';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import './style.css';

interface IBadgeProps {
	className: string;
}

const Badge: FunctionComponent<PropsWithChildren<IBadgeProps>> = ({ children, className }) => {
	return <div className={classNames(className, 'badge')}>{children}</div>;
};

export default Badge;
