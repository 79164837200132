import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import React, { FC, useEffect, useRef } from 'react';
import { restoreSelection, saveSelection } from 'vgui/helpers/selection';
import useAutoOptionsPosition from 'vgui/hooks/useAutoOptionsPosition.tsx';

import './style.css';

interface IPopoverProps {
	closeTimeout?: number;
	className: string;
	rootElement: (props: {
		ref: any;
		onClick: any;
		isComponentVisible: boolean;
		setIsComponentVisible: (val: boolean) => void;
	}) => JSX.Element;
}

type TChildren = (props: { setIsComponentVisible: (val: boolean) => void }) => React.ReactNode;

type IPopover = FC<IPopoverProps & { children: TChildren }>;

const Popover: IPopover = (props) => {
	const { className, children, closeTimeout = 60000, rootElement } = props;
	const rootRef = useRef();
	const rangeRef = useRef();
	const { ref: popoverRef, isComponentVisible, setIsComponentVisible } = useOutsideClick(false);

	const { optionsRef } = useAutoOptionsPosition(isComponentVisible, rootRef);

	useEffect(() => {
		if (!isComponentVisible) return;

		const autoclose = setTimeout(() => {
			setIsComponentVisible(false);
		}, closeTimeout);

		rangeRef.current = saveSelection();

		return () => {
			clearTimeout(autoclose);
			restoreSelection(rangeRef.current);
		};
	}, [isComponentVisible, closeTimeout, setIsComponentVisible]);

	const onClick = () => {
		setIsComponentVisible((val) => !val);
	};

	return (
		<div ref={popoverRef}>
			{rootElement({ ref: rootRef, onClick, isComponentVisible, setIsComponentVisible })}
			<div ref={optionsRef} className={classNames('popover-new', { hidden: !isComponentVisible }, className)}>
				{typeof children === 'function' ? children({ setIsComponentVisible }) : children}
			</div>
		</div>
	);
};

export default Popover;
