import classNames from 'classnames';
import { useState } from 'react';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';
import { Photo } from 'vgui';

const PhotoGrid = ({ items, setView, activeView }) => {
	const ref = useRef(null);
	const [sizes, setSizes] = useState({
		width: 0,
		height: 0,
	});

	useLayoutEffect(() => {
		setTimeout(() => {
			if (!ref?.current) return;
			const { clientWidth } = ref.current;

			setSizes({
				width: clientWidth,
				height: clientWidth > maxHeight ? maxHeight : clientWidth,
			});
		}, 1);
	}, [ref]);

	if (!items.length) return;

	const maxItems = 5;
	const maxHeight = 400;
	const horizontalDirection = items[0].context.height > items[0].context.width;
	const maxFirstItemsCount = items.length < maxItems ? 2 : 1;
	const firstItemsSpacePercent = maxFirstItemsCount === items.length ? 1 : maxFirstItemsCount === 2 ? 0.5 : 0.75;

	const overrideItems = (filter) =>
		items
			.map((value, i) => ({
				...value,
				onClick: () =>
					setView({
						active: !activeView.active,
						slide: i + 1,
					}),
			}))
			.filter(filter);

	const firstItemsInfo = () => {
		const firstItems = overrideItems((_, i) => i < maxFirstItemsCount);

		return {
			items: firstItems,
			width: Math.ceil(horizontalDirection ? sizes.width / firstItems.length : sizes.width * firstItemsSpacePercent),
			height: Math.ceil(
				horizontalDirection ? sizes.height * firstItemsSpacePercent : sizes.height / firstItems.length
			),
		};
	};

	const secondItemsInfo = () => {
		const secondItems = overrideItems((_, i) => i >= maxFirstItemsCount && i < maxItems);

		return {
			items: secondItems,
			width: Math.ceil(
				horizontalDirection ? sizes.width / secondItems.length : sizes.width * (1 - firstItemsSpacePercent)
			),
			height: Math.ceil(
				horizontalDirection ? sizes.height * (1 - firstItemsSpacePercent) : sizes.height / secondItems.length
			),
			hiddenItems: items.length > maxItems ? items.length - maxItems : undefined,
		};
	};

	return (
		<div className={classNames('flex w-full', { 'flex-col': horizontalDirection })} ref={ref}>
			{ref.current &&
				[firstItemsInfo(), secondItemsInfo()].map((info, key) => (
					<ItemsContainer key={key} {...{ ...info, horizontalDirection }} />
				))}
		</div>
	);
};

const ItemsContainer = ({ width, height, items, horizontalDirection, hiddenItems }) => (
	<div className={classNames('flex', { 'flex-col': !horizontalDirection })}>
		{items.map(({ context: { name, url }, onClick }, key) => (
			<div key={key} className="cursor-pointer flex p-[2px]" style={{ width, height }} onClick={onClick}>
				<Photo
					wrapperClassName="!w-full !h-full"
					className="object-cover"
					alt={name || 'img'}
					placeholder
					{...{
						url,
						width: width - 2,
						height: height - 2,
					}}
				/>
				{hiddenItems && key === items.length - 1 && (
					<div className="absolute w-full h-full top-0 left-0 p-[2px]">
						<div className="w-full h-full flex justify-center items-center text-3xl text-white bg-gray-400 bg-opacity-30 rounded">
							+{hiddenItems}
						</div>
					</div>
				)}
			</div>
		))}
	</div>
);

export default PhotoGrid;
