import classNames from 'classnames';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { Modal } from '../modal';
import { Offcanvas } from '../offcanvas';

const AdaptiveModal = ({ show, onHide, children, className }) => {
	const { size } = useWindowDimensions();

	return size === 'sm' ? (
		<Offcanvas show={show} onHide={onHide} className={className}>
			{children}
		</Offcanvas>
	) : (
		<Modal show={show} onHide={onHide} className={className}>
			{children}
		</Modal>
	);
};

const Header = ({ children, onHide, className, ...props }) => {
	const { size } = useWindowDimensions();

	if (size === 'sm') return;

	return (
		<Modal.Header onHide={onHide} className={classNames('py-3', className)} {...props}>
			{children}
		</Modal.Header>
	);
};

const Body = ({ children, ...props }) => <Modal.Body {...props}>{children}</Modal.Body>;

const Footer = ({ children, ...props }) => <Modal.Footer {...props}>{children}</Modal.Footer>;

AdaptiveModal.Header = Header;
AdaptiveModal.Body = Body;
AdaptiveModal.Footer = Footer;

export default AdaptiveModal;
