import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import React, { useRef, useState } from 'react';

import { Button } from '../button';
import { DropdownNew } from '../dropdown';
import InputNew, { IInput } from '../input/Input.tsx';

interface ISelect extends IInput {
	classOptions?: string;
	defaultValue?: string;
	name?: string;
	placeholder?: string;
	maxHeight?: number;
}

type SelectComponent = React.ForwardRefExoticComponent<ISelect & React.RefAttributes<HTMLInputElement>> & {
	Option?: (props: any) => JSX.Element;
};

function useCombinedRefs<T>(...refs) {
	const targetRef = React.useRef<T>();

	React.useEffect(() => {
		refs.forEach((ref) => {
			if (!ref) return;

			if (typeof ref === 'function') {
				ref(targetRef.current);
			} else {
				ref.current = targetRef.current;
			}
		});
	}, [refs]);

	return targetRef;
}

const Select: SelectComponent = React.forwardRef<HTMLInputElement, ISelect>((props, ref) => {
	const { className, classOptions, placeholder, defaultValue = '', maxHeight, options, onChange, ...other } = props;

	const { ref: selectRef, isComponentVisible, setIsComponentVisible } = useOutsideClick(false);
	const inputRef = useRef<HTMLInputElement>();
	const inputHideRef = useRef<HTMLInputElement>();
	const combinedRef = useCombinedRefs<HTMLInputElement>(ref, inputHideRef);
	const [id, setId] = useState(defaultValue);
	const changeHandler = (e) => {
		if (onChange) onChange(e);
	};

	const handleChange = ({ id }) => {
		setId(id);
		setValue(id);
	};

	const setValue = (value: string) => {
		const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
		nativeInputValueSetter.call(combinedRef.current, value);
		combinedRef.current.dispatchEvent(new Event('input', { bubbles: true }));
	};

	return (
		<div className={classNames('select', className)} ref={selectRef}>
			<InputNew
				tabIndex={-1}
				value={options.find((opt) => id === opt.id)?.value}
				ref={inputRef}
				placeholder={placeholder}
				readOnly
				onClick={() => {
					setIsComponentVisible((val) => !val);
					inputRef.current.focus();
				}}
				classInput="pointer-events-none"
				className="cursor-pointer select-none"
			>
				<Button variant="success-fill" size="small" className="pointer-events-none">
					<ChevronDownIcon
						className={classNames('w-4 h-4 transition-transform', { 'rotate-180': isComponentVisible })}
					/>
				</Button>
			</InputNew>
			<input tabIndex={-1} className="hidden" {...other} onChange={changeHandler} ref={combinedRef} value={id} />
			<DropdownNew
				{...{
					maxHeight,
					isComponentVisible,
					setIsComponentVisible,
					className: classOptions,
					options,
					id: defaultValue,
				}}
				ref={inputRef}
				onChange={handleChange}
			/>
		</div>
	);
});

export default Select;
