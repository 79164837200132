import classNames from 'classnames';
import { isURL } from 'helpers';
import { parseEmoji } from 'helpers/emoji';
import { toUnicode } from 'helpers/punycode.ts';
import { domToReact } from 'html-react-parser';
import React, { forwardRef } from 'react';
import { Link } from '../elements';

import './style.css';

const toLink = (url, children) => {
	const hasSpace = children && typeof children == 'string' && children.trim().includes(' ');

	try {
		if (!hasSpace && children?.length > 75) children = toUnicode(decodeURI(children)).substring(0, 75) + '...';
	} catch {}

	return (
		<Link className={classNames(!hasSpace && children?.length > 30 && 'break-all')} to={url} title={url}>
			{children}
		</Link>
	);
};

const HtmlBlock = forwardRef(({ content, children, className, ...props }, ref) => {
	return (
		<>
			<div ref={ref} {...props} className={classNames('html-block', className)}>
				{parseEmoji(children || content, {
					replace: ({ name, attribs, children, data }) => {
						if (name === 'a' && attribs.href) return toLink(attribs.href, domToReact(children));
						if (data && isURL(data)) return toLink(data, data);
					},
				})}
			</div>
		</>
	);
});

export default HtmlBlock;
