import { useEffect, useRef } from 'react';

const useAutoOptionsPosition = (
	isComponentVisible: boolean,
	inputRef: React.MutableRefObject<any>,
	options?: Array<{ id: number | string; value: string }>
) => {
	const optionsRef = useRef<HTMLDivElement>();

	useEffect(() => {
		if (!isComponentVisible || !optionsRef.current || !inputRef.current) return;

		const opt = optionsRef.current;

		const { bottom, top } = inputRef.current.getBoundingClientRect();
		const { height, x } = optionsRef.current.getBoundingClientRect();
		const bottomPlace = height + bottom + 100 > window.innerHeight;
		const toTop = bottomPlace && top - height > 0;

		if (!toTop && bottomPlace) {
			const newHeight = window.innerHeight - bottom;

			opt.style.height = `${newHeight - 16}px`;
		}

		if (x < 0) {
			opt.style.marginRight = `${x}px`;
			opt.style.marginLeft = `${x}px`;
		}

		const correctClasses = toTop ? ['bottom-full', 'mb-1'] : ['top-full', 'mt-1'];

		opt.classList.add(...correctClasses);

		return () => {
			opt.classList.remove('bottom-full', 'mb-1', 'top-full', 'mt-1');
			opt.style.height = '';
			opt.style.marginRight = '';
		};
	}, [isComponentVisible, optionsRef, inputRef, options]);

	return { optionsRef };
};

export default useAutoOptionsPosition;
