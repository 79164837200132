import React, { FunctionComponent, PropsWithChildren } from 'react';

interface ILayoutProps {
	className?: string;
}

const Layout: FunctionComponent<PropsWithChildren<ILayoutProps>> = ({ children, className }) => {
	return <div className={className}>{children}</div>;
};

export default Layout;
