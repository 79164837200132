import classNames from 'classnames';

import './style.css';

const Pressable = ({ children, className, onClick = () => {}, ...props }) => {
	return (
		<div
			className={classNames('pressable', className)}
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
				onClick(e);
			}}
			{...props}
		>
			{children}
		</div>
	);
};

export default Pressable;
