import classNames from 'classnames';

import './style.css';

const Tabs = ({ className, children, ...props }) => (
	<>{children}</>
	// <div className={classNames('tabs', className)} {...props}>
	// 	{children}
	// </div>
);

Tabs.List = ({ className, children, ...props }) => (
	<div className="nav-scroll-container">
		<ul className={classNames('nav nav-tabs', className)} {...props}>
			{children}
		</ul>
	</div>
);

Tabs.Item = ({ id, label, active, className, disabled, children, ...props }) => (
	<li className={classNames('nav-item', className)} role="presentation" {...props}>
		<span
			className={classNames('nav-link', active && 'active')}
			id={`${id}-tab`}
			role="tab"
			data-bs-toggle="pill"
			data-bs-target={`#${id}`}
			aria-controls={id}
			aria-selected={active}
		>
			{label || children}
		</span>
	</li>
);

Tabs.Panel = ({ id, active, children, className, ...props }) =>
	active && (
		<div
			className={classNames(className, 'tab-pane', !active && 'hidden')}
			id={id}
			role="tabpanel"
			aria-labelledby={`${id}-tab`}
			{...props}
		>
			{children}
		</div>
	);

export default Tabs;
