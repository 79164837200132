import classNames from 'classnames';
import * as SIZE from 'constants/Size';
import React, { FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import { Image } from 'vgui';

interface IPhotoProps {
	url: string;
	mode: 'resize' | 'crop';
	rounded: boolean;
	placeholder: boolean;
	width: number;
	alt: string;
	height: number;
	className: string;
	wrapperClassName: string;
	size: string;
	maxHeight: number;
	onClick: (e: MouseEvent<HTMLElement>) => void;
}

const Photo: FunctionComponent<IPhotoProps> = (props) => {
	const {
		url,
		mode = 'crop',
		rounded,
		width,
		alt,
		height,
		className,
		wrapperClassName,
		size = null,
		placeholder = false,
		maxHeight = SIZE.MAX_HEIGHT,
		onClick = () => {},
	} = props;

	const [currentSize, setCurrentSize] = useState(null);

	useEffect(() => {
		if (size !== null) {
			const [newWidth, newHeight] = size.split('x').map((value) => +value);
			setCurrentSize({ width: newWidth, height: newHeight });
		}

		if (height > maxHeight) {
			setCurrentSize({ width: Math.ceil((maxHeight * width) / height), height: maxHeight });
		} else if (width && height) {
			setCurrentSize({ width: width, height: height });
		}
	}, [size, maxHeight, height, width]);

	if (!currentSize) return null;

	const src = url.match(/.gif$/) ? url : SIZE[mode](url, currentSize.width + 'x' + currentSize.height);

	return (
		<div className={classNames('flex justify-center', wrapperClassName)}>
			<Image
				alt={alt}
				src={src}
				width={currentSize.width}
				height={currentSize.height}
				className={classNames(
					'cursor-pointer',
					rounded ? 'rounded-full' : 'rounded',
					placeholder && 'bg-gray-200 dark:bg-gray-800 border-none outline-0',
					className,
				)}
				onClick={onClick}
			/>
		</div>
	);
};

export default Photo;
