import classNames from 'classnames';
import { Pressable, Link } from 'vgui';

const SideNav = ({ children, className }) => (
	<nav className={classNames(className, 'sidenav flex flex-col')}>{children}</nav>
);

const SideNavLink = ({ className, active, title, children, minimize, ...props }) => (
	<Link className={classNames(className, 'sidenav-link', { active })} {...props}>
		<span className="w-8 h-8 flex flex-auto justify-center items-center">
			<span className="w-6 flex justify-center">{children}</span>
		</span>
		{title && <span className={`pr-2 hidden ${minimize ? '' : 'xl:block'}`}>{title}</span>}
	</Link>
);

const SideNavBrand = ({ className, icon, children, minimize, ...props }) => {
	return (
		<Pressable className={classNames('sidenav-brand', className)} {...props}>
			<span className="w-8">{icon}</span>
			<span className={`hidden ${minimize ? '' : 'xl:block'}`}>{children}</span>
		</Pressable>
	);
};
SideNav.Link = SideNavLink;
SideNav.Brand = SideNavBrand;

export default SideNav;
