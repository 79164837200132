import classNames from 'classnames';
import React from 'react';

import './style.css';

const TextArea = React.forwardRef(({ value, className, placeholder, error, ...props }, ref) => {
	return (
		<textarea
			className={classNames(`text-area`, className, error ? 'text-area-error' : '')}
			placeholder={placeholder}
			value={value}
			{...props}
			ref={ref}
		/>
	);
});

export default TextArea;
